import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput, EmailInput } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { signInSchema } from "./SignInForm.helpers";
import Styles from "./SignInForm.styles";
import { SignInFormValues, SignInFormProps as Props } from "./SignInForm.types";
import Button from "components/global/Button/Button";
import PasswordStrength from "components/global/PasswordStrength/PasswordStrength";
import { trimFields } from "utils/form.utils";

const SignInForm: React.FC<Props> = props => {
  const { initialValues, currentForm = "signIn", isFetched = false } = props;
  const { buttonTitle = "Ingresar", onSubmitSuccess, className } = props;
  const { errorMessage, focusHandler } = props;
  const { push } = useRouter();
  const { handleSubmit, register, watch, formState } =
    useForm<SignInFormValues>({
      mode: "onChange",
      resolver: yupResolver(signInSchema(currentForm)),
      defaultValues: initialValues
    });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { email, password } = formState.errors ?? {};

  const submitHandler = async (form: SignInFormValues) => {
    const newForm = trimFields(form);
    const { email, password } = newForm;
    setLoading(true);
    await onSubmitSuccess(email, password);
    setLoading(false);
  };

  const recoverPassword = () => {
    setDisabled(true);
    push("/recover");
  };

  useEffect(() => {
    if (isFetched) setLoading(false);
  }, [isFetched]);

  return (
    <Styles
      className={`SignInForm ${className}`}
      onSubmit={handleSubmit(submitHandler)}
    >
      <EmailInput
        {...register("email")}
        className="field SignInForm__email-input"
        label="Correo electrónico"
        placeholder="hola@email.com"
        onFocus={focusHandler}
        errorMessage={email?.message}
        maxLength={50}
      />
      <PasswordInput
        {...register("password")}
        label="Contraseña"
        className="field SignInForm__password-input"
        errorMessage={password?.message || errorMessage}
        onFocus={focusHandler}
      />
      {currentForm === "signUp" ? (
        <PasswordStrength
          className="SignInForm__password-strength"
          password={watch("password")}
        />
      ) : null}
      <div className="SignInForm__buttons">
        {currentForm === "signIn" ? (
          <div className="SignInForm__forgottenPassword">
            <p className="SignInForm__text">¿Olvidaste tu contraseña?</p>
            <Button
              htmlType="button"
              className="SignInForm__forgot"
              type="LINK"
              disabled={disabled}
              onClick={recoverPassword}
            >
              Recuperar contraseña
            </Button>
          </div>
        ) : null}
        <Button
          htmlType="submit"
          type="FILLED"
          color="primary"
          disabled={disabled}
          className="SignInForm__submit"
          isLoading={loading}
        >
          {buttonTitle}
        </Button>
      </div>
    </Styles>
  );
};

SignInForm.defaultProps = {
  className: ""
};

export default SignInForm;
