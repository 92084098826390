import { DiversityType, passwordStrength } from "check-password-strength";
import React, { useMemo } from "react";

import { allowedSymbols, checkLength } from "./PasswordStrength.helpers";
import { defaultOptions } from "./PasswordStrength.helpers";
import Styles from "./PasswordStrength.styles";
import { PasswordStrengthProps as Props } from "./PasswordStrength.types";
import { Strength } from "./PasswordStrength.types";
import PasswordStrengthLines from "./PasswordStrengthLines/PasswordStrengthLines";

import GreenCheckedSVG from "../../../../public/assets/images/anefi/small-green-circle-check.svg";
import MinusSVG from "../../../../public/assets/images/anefi/minus-circle.svg";
import RedCheckedSVG from "../../../../public/assets/images/anefi/small-red-circle-minus.svg";

const PasswordStrength: React.FC<Props> = props => {
  const { className, password } = props;
  const diversityType = useMemo(() => {
    if (typeof password === "undefined" || password?.trim() === "") return;
    const { contains } = passwordStrength<Strength>(
      password,
      defaultOptions,
      allowedSymbols
    );
    return contains;
  }, [password]);

  const renderIcon = (diversity: DiversityType) => {
    if (!diversityType) return <MinusSVG className="PasswordStrength__icon" />;

    if (diversityType.includes(diversity)) {
      return <GreenCheckedSVG className="PasswordStrength__icon" />;
    }

    return <RedCheckedSVG className="PasswordStrength__icon" />;
  };

  const renderLength = () => {
    if (checkLength(password?.length) === undefined) {
      return <MinusSVG className="PasswordStrength__icon" />;
    }

    if (!checkLength(password?.length)) {
      return <RedCheckedSVG className="PasswordStrength__icon" />;
    }

    return <GreenCheckedSVG className="PasswordStrength__icon" />;
  };

  return (
    <Styles className={`PasswordStrength ${className}`}>
      <PasswordStrengthLines password={password} />
      <div className="PasswordStrength__conditions">
        <p className="PasswordStrength__list-title">
          La contraseña debe contener:
        </p>
        <div className="PasswordStrength__condition">
          {renderIcon("uppercase")}
          <p className="PasswordStrength__message">
            Al menos una letra mayúscula
          </p>
        </div>
        <div className="PasswordStrength__condition">
          {renderIcon("lowercase")}
          <p className="PasswordStrength__message">
            Al menos una letra minúscula
          </p>
        </div>
        <div className="PasswordStrength__condition">
          {renderIcon("number")}
          <p className="PasswordStrength__message">Al menos un número</p>
        </div>
        <div className="PasswordStrength__condition">
          {renderIcon("symbol")}
          <p className="PasswordStrength__message">
            Al menos un caracter especial
          </p>
        </div>
        <div className="PasswordStrength__condition">
          {renderLength()}
          <p className="PasswordStrength__message">Mínimo 7 caracteres</p>
        </div>
      </div>
    </Styles>
  );
};

PasswordStrength.defaultProps = {
  className: ""
};

export default PasswordStrength;
