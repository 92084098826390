// PasswordStrength helper functions and data
import { Options } from "check-password-strength";
import { CONSTANTS } from "@anefi/utils";

import { Strength } from "./PasswordStrength.types";

const { PASSWORD_LENGTH } = CONSTANTS;

export const defaultOptions: Options<Strength> = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 7
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 3,
    minLength: 8
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 10
  }
];

export const allowedSymbols = "#^<>;,:.'_@$!%*&\"¡¿?°/()=|+~{}-";

export const checkLength = (length: number | undefined) => {
  if (!length) return;
  return length >= PASSWORD_LENGTH;
};
