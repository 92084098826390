import styled from "styled-components";

import { CONSTANTS } from "../../../../config/constants";
import { SignInFormStyledProps as Props } from "./SignInForm.types";

const { desktop } = CONSTANTS.BREAKPOINTS;

const SignInFormStyled = styled.form<Props>`
  background-color: var(--palette-gray-s0-l97);

  .SignInForm {
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 4rem;
      padding-bottom: 1.6rem;

      @media (max-width: ${desktop}px) {
        padding-top: 2.4rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 6rem;
      }
    }

    &__text {
      color: var(--palette-gray-s0-l50);
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__email-input {
      padding-bottom: 3.4rem;
    }

    &__forgot {
      padding: 0;
      font-weight: 600;
      color: var(--palette-black);

      @media (max-width: ${desktop}px) {
        margin-bottom: 1.6rem;
      }
    }

    &__submit {
      font-weight: 600;
      align-self: center;
      padding: 1rem 5.2rem;
    }

    &__password-strength {
      padding-top: 2.4rem;
    }
  }
`;

export default SignInFormStyled;
